<template>
    <section class="bg-white page">
        <h2 class="mb-3">Tasks</h2>
        <div class="row">
            <div class="col-2">
                <b-form @submit.prevent="loadTasks">
                    <b-form-group label="Task Status" class="mr-md-2">
                        <b-form-select v-model="s.task_status" :options="$store.state.taskStatus"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Task Types" class="mr-md-2">
                        <b-form-select v-model="s.task_type" :options="$store.state.taskTypes"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Created By" class="mr-md-2">
                        <b-form-select v-model="s.created_by" :options="users"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Assigned To" class="mr-md-2">
                        <b-form-select v-model="s.assigned_to" :options="users"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Customer" class="mr-md-3">
                        <b-form-select v-model="s.customer" :options="customers"></b-form-select>
                    </b-form-group>
                    <b-button class="mt-md-4" type="submit"><i class="fa fa-search"></i> Search</b-button>
                </b-form>
            </div>
            <div class="col-10">

                <div class="card-columns">
                    <task v-for="t of tasks" :key="t.id" :t="t"></task>
                    <b-alert variant="warning" show v-if="tasks.length === 0">No Tasks Found</b-alert>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
    import Task from "./task";

    export default {
        name: 'Tasks',
        components: {Task},
        data() {
            return {
                tasks: [],
                s: {
                    task_status: 'ASSIGNED',
                    task_type: '',
                    created_by: '',
                    assigned_to: '',
                    contact_id: ''
                }
            }
        },
        created() {
        },
        computed: {
            users: function () {
                return this.$store.state.users.filter(u => u.value)
            },
            customers: function () {
                return this.$store.state.customers.filter(u => u.value)
            }
        },
        methods: {
            loadTasks: function () {
                axios.post("/api/task/search", this.s)
                    .then(resp => {
                        this.tasks = (resp.data || []).map(t => {
                            t['task_due'] = moment(t['task_due']).format("YYYY-MM-DD")
                            t['created_at'] = moment(t['created_at']).format("YYYY-MM-DD")
                            return t
                        })
                    })
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
