<template>
  <section class="page">
    <div class="d-flex w-100 bg-white mb-5 mt-3 p-3 justify-content-between flex-column flex-md-row">
      <h1 class="d-flex align-items-center text-muted flex-fill">
        <i class="fas fa-2x fa-tachometer-alt tm-site-icon"></i>
        <span class="ml-2" v-if="$store.state.tenantAdmin || $store.state.manager">Dashboard</span>
        <span class="ml-2" v-else>My Dashboard</span>
      </h1>
      <div class="d-flex align-items-center ml-3 flex-column flex-md-row">

        <b-form-select v-model="days" :options="dayOptions" @change="loadDashboard">
        </b-form-select>
        <div class="d-flex flex-grow-1 ml-md-2 mt-2 mt-md-0"
             v-if="days === -1"
             style="width: 300px">
          <b-form-datepicker id="example-datepicker"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             locale="en-IN"
                             @input="loadChart"
                             v-model="daySelect"></b-form-datepicker>

        </div>

      </div>
    </div>
    <div v-if="$store.state.tenantAdmin || $store.state.manager"
         class="d-flex w-100 bg-white mb-5 mt-3 p-3 justify-content-between flex-column flex-md-row">
      <h1 class="d-flex align-items-center text-muted flex-fill">
        <i class="fas fa-2x fa-tachometer-alt tm-site-icon"></i>
        <span class="ml-2">Consolidated Report</span>
      </h1>
      <div class="d-flex align-items-center ml-3 flex-column flex-md-row">
        <form action="/api/summary" method="get" ref="form">
          <div
              class="d-flex flex-grow-1 ml-md-2 mt-2 mt-md-0 align-items-center align-content-center justify-content-end">

            <b-form-group label="From Date">
              <b-form-datepicker id="example-datepicker" v-model="cFrom" name="from"></b-form-datepicker>
            </b-form-group>

            <b-form-group label="To Date" class="ml-2 mr-2">
              <b-form-datepicker id="example-datepicker-2" v-model="cTo" name="to"></b-form-datepicker>
            </b-form-group>

            <b-button @click.prevent="getCReport()" variant="success">
              Get
            </b-button>
          </div>
        </form>

      </div>
    </div>

    <div class="mb-5 d-flex justify-content-between align-items-center flex-column flex-md-row p-3 p-md-5 bg-white">

      <div class="highlight mr-md-2 mt-3 card text-white bg-info">
        <div class="card-header">
          <a
              href="#/customer-reports"
              class="text-white">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 text-left">
                <font-awesome-icon :icon="['far', 'user-crown']" size="3x"></font-awesome-icon>
              </div>
              <div class="text-right report-count">{{ reports.meeting }}</div>
            </div>
          </a>
        </div>
        <div class="card-body small">
          # of Meetings
        </div>
      </div>
      <div class="highlight mr-md-2 mt-3 card text-white bg-success">
        <div class="card-header">
          <a
              href="#/customer-reports"
              class="text-white">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 text-left">
                <font-awesome-icon :icon="['far', 'people-arrows']" size="3x"></font-awesome-icon>
              </div>
              <div class="text-right report-count">{{ reports.comm }}</div>
            </div>
          </a>
        </div>
        <div class="card-body small">
          # of Communications
        </div>
      </div>
      <div class="highlight mr-md-2 mt-3 card text-white bg-primary">
        <div class="card-header">
          <a
              href="#/customer-reports"
              class="text-white">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 text-left">
                <font-awesome-icon :icon="['fab', 'whatsapp']" size="3x"></font-awesome-icon>
              </div>
              <div class="text-right report-count">{{ reports.wa }}</div>
            </div>
          </a>
        </div>
        <div class="card-body small">
          WhatsApp Messages
        </div>
      </div>

      <div class="highlight mr-md-2 mt-3 card text-white bg-secondary">
        <div class="card-header">
          <a
              href="#/customer-reports"
              class="text-white">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 text-left">
                <font-awesome-icon :icon="['far', 'phone']" size="3x"></font-awesome-icon>
              </div>
              <div class="text-right report-count">{{ reports.phone }}</div>
            </div>
          </a>
        </div>
        <div class="card-body small">
          Phone Calls
        </div>
      </div>
      <div class="highlight mr-md-2 mt-3 card text-black-50 bg-warning" v-if="$store.state.tenantAdmin">
        <div class="card-header">
          <a
              href="#/customer-reports"
              class="text-black-50">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 text-left">
                <font-awesome-icon :icon="['far', 'user-slash']" size="3x"></font-awesome-icon>
              </div>
              <div class="text-right report-count">{{ reports.inactive }}</div>
            </div>
          </a>
        </div>
        <div class="card-body small">
          Inactive Users
        </div>
      </div>
    </div>
    <div class="mb-5 d-flex justify-content-between flex-column p-3 p-md-5 bg-white">
      <!-- Bar Chart of sales %-->
      <h5 class="mb-3 text-center">Sales Performance</h5>
      <div class="d-none d-md-block">
        <column-chart :data="chartData" :min="0" :max="100"></column-chart>
      </div>
      <div class="d-block d-md-none">
        <bar-chart :data="chartData" :min="0" :max="100"></bar-chart>
      </div>

      <a :href="printUrl" target="_blank" class="text-center m-3">Print Report</a>
    </div>
    <div class="mb-5 d-flex flex-wrap justify-content-center flex-column flex-md-row bg-white p-1 p-md-3"
         v-if="$store.state.tenantAdmin || $store.state.manager">
      <div class="card m-2 user-card"
           v-for="(u, idx) of userStatusSorted" :key="u.user.phone">
        <div
            class="card-header p-2 d-flex flex-row align-content-center align-items-center justify-content-between small"
            :style="getStyle(idx)">
          <b-avatar size="2rem" :text="u.user.name.substring(0,2)" :src="u.user.image"></b-avatar>
          <div class="d-flex justify-content-end align-content-center align-items-center">
            <div class="ml-1 text-right" style="max-width: 150px; font-size: 1.1em">
              {{ u.user.name }}
            </div>
            <b-button-group class="ml-3">
              <b-button size="sm"
                        v-b-popover.hover.top="'What is the Version of App Installed by User'" title="App Version"
                        variant="primary" @click="sendAppUpdate(u.user)">
                {{ makeScore(u.score) }}%
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div class="card-body d-flex flex-column small">

          <div class="list-group list-group-flush">
            <div class="list-group-item" v-if="u.is_active">
              <font-awesome-icon fixed-width :icon="['fas', 'user-crown']"></font-awesome-icon>
              Active
            </div>
            <div class="list-group-item-danger" v-if="!u.is_active">
              <font-awesome-icon fixed-width :icon="['fas', 'user-slash']"></font-awesome-icon>
              In-Active
            </div>
            <div class="list-group-item">

              <div class="d-flex justify-content-start align-items-center"
                   v-bind:class="{'text-danger': !u.wa_session}">
                <font-awesome-icon fixed-width
                                   :icon="['fab', 'whatsapp']"></font-awesome-icon>
                <span v-if="u.wa_session" class="ml-1">Connected</span>
                <span v-else class="ml-1">DisConnected</span>
              </div>
            </div>
            <div class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'phone']"></font-awesome-icon>
              {{ u.last_call }}
            </div>
            <div class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'eye']"></font-awesome-icon>
              {{ u.last_seen }}
            </div>
            <div class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'tag']"></font-awesome-icon>
              {{ u.last_tag }}
            </div>
            <div class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'location']"></font-awesome-icon>
              {{ u.last_location }}
              <b-button v-if="hasLocation(u)" @click="showLocation(u)">
                <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
              </b-button>
            </div>
            <div v-if="u.distance" class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'globe']"></font-awesome-icon>
              {{ u.distance }} KM
              <b-button @click="showDistance(u)" size="sm">
                <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
              </b-button>
            </div>
            <div class="list-group-item">
              <font-awesome-icon fixed-width :icon="['fas', 'code-merge']"></font-awesome-icon>
              {{ u.app_version || '-NA-' }}
            </div>

            <b-button-group class="mt-3 d-none d-md-block" size="sm">
              <b-button class="border-0"
                        v-for="t of tags" :key="t" :style="tagStyle(t)"
                        :title="`# of Tags of ${t}`"
                        v-b-popover.hover="`${u.user.name} Tagged ${t} = ${tagCount(t, u)}`">
                {{ tagCount(t, u) }}
              </b-button>
            </b-button-group>
          </div>
        </div>

        <div class="card-footer text-right small">
          <a :href="'#/employee-reports/'+u.user.id+'/'+days+'/'+daySelect">
            <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
            View Detailed Communications
          </a>
        </div>
      </div>
    </div>


    <b-modal id="wa-status" :title="`WhatsApp status for ${selectedUser.user.name}`" ok-only>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between">
          <span>WhatsApp QR Scan</span>
          <font-awesome-icon icon="check" class="text-success" v-if="selectedUser.wa_session"></font-awesome-icon>
          <font-awesome-icon icon="times" class="text-danger" v-else></font-awesome-icon>
        </div>
        <div class="list-group-item d-flex justify-content-between">
          <span>WhatsApp Manual Disconnect?</span>
          <span v-if="selectedUser.wa_disconnected" class="text-danger">Yes</span>
          <span v-else>No</span>
        </div>
        <div class="list-group-item d-flex justify-content-between">
          <span>WhatsApp Temporary Connection Error</span>
          <span
              v-if="selectedUser.connection_lost && !selectedUser.wa_disconnected  && selectedUser.wa_conn_retry_count > 0"
              class="text-warning">Yes</span>
          <span v-else>No</span>
        </div>
      </div>
      <div class="d-flex justify-content-start w-100 m-5"
           v-if="selectedUser.wa_disconnected || !selectedUser.wa_session">
        <b-button @click="askUserToWAConnect()" size="lg" variant="info">
          Inform User to Connect WhatsApp
        </b-button>
      </div>
    </b-modal>
    <b-modal id="user-location" hide-footer :title="userLocationTitle">
      <div class="d-flex justify-content-center">
        <l-map
            ref="map2"
            style="height: 300px; width: 300px;"
            :zoom="zoom"
            :center="latlng">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="latlng"></l-marker>
        </l-map>
      </div>
    </b-modal>
    <b-modal id="user-travel" hide-footer :title="userTravelTitle" size="xl">
      <div class="d-flex justify-content-center">

        <l-map style="height: 80vh; width: 50vw"
               :zoom="zoom - 3"
               ref="map3"
               :center="latlng">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-polyline :lat-lngs="travel"></l-polyline>
          <l-marker v-for="(ll, idx) of travel" :lat-lng="ll" :key="idx" :icon="getIcon(idx)"></l-marker>
        </l-map>

        <div class="list-group ml-3">
          <div class="list-group-item d-flex">
            <img src="/home.png" style="max-height:32px">
            <div class="ml-2">
              {{ homeLoc.addr }}
            </div>
          </div>
          <div v-for="(m, idx) of meetings" :key="idx" class="list-group-item d-flex">
            <img src="/meeting.png" style="max-height: 32px">
            <div class="ml-2" style="max-width: 300px">
              {{ m.addr }}
            </div>
            <div class="ml-2 text-right justify-content-end align-content-end flex-grow-1">
              {{ m.distance }} KM
            </div>
          </div>
          <div class="list-group-item list-group-item-info d-flex justify-content-between">
            <span>Total Distance: </span>
            <span>{{ distance }} KM</span>
          </div>
        </div>
      </div>
    </b-modal>

  </section>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { LMap, LTileLayer, LMarker, LPolyline } from 'vue2-leaflet'
import { icon } from 'leaflet'

export default {
  name: 'Dashboard',
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      userLocationTitle: '',
      userTravelTitle: '',
      tags: ['CUSTOMER', 'QUALIFIED_LEAD', 'INTERNAL', 'SPAM', 'UNKNOWN'],
      //[['Sun', 32], ['Mon', 46], ['Tue', 28]]
      chartData: [],
      homeIcon: icon({
        iconUrl: '/home.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      meetingIcon: icon({
        iconUrl: '/meeting.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      selectedUser: {
        user: {
          name: ''
        }
      },
      cFrom: '',
      cTo: '',
      p: {
        password: '',
        password2: ''
      },
      taskDate: new Date(),
      days: 0,
      user_id: null,
      visible: false,
      qrCodeInitiated: false,
      qrCode: '',
      daySelect: moment().format('YYYY-MM-DD'),
      timeRemaining: 0,
      visibleAt: new Date(),
      visibleTill: new Date(),
      dayOptions: [
        {text: 'Today', value: 0},
        {text: 'Yesterday', value: 1},
        {text: 'Custom Date', value: -1},
      ],
      reports: {
        cust: 0,
        comm: 0,
        meeting: 0,
        wa: 0,
        sms: 0,
        phone: 0,
        err: 0,
        inactive: 0
      },
      task: {},
      userChart: [],
      customerCountTable: [],
      legendUsers: [],
      userStatus: [],
      tasks: [],
      colors: [],
      interval: null,
      latlng: [],
      travel: [],
      homeLoc: {},
      meetings: [],
      distance: 0
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline
  },
  async destroyed () {
  },
  async created () {
    try {
      await this.$store.dispatch('checkUserLogin')
      await this.$store.dispatch('loadCustomers')
      await this.$store.dispatch('loadUsers')
      await this.loadDashboard()

    } catch (e) {
      console.error('exception in loading data', e)
    }
  },
  computed: {
    printUrl () {
      return `${process.env.VUE_APP_API}/report?tenant_id=${this.$store.state.tenantId}&back=${this.days}&date=${this.daySelect}`
    },
    userStatusSorted () {
      return _.reverse(_.sortBy(this.userStatus, 'score'))
    },
    validPass () {
      return (this.p.password.length > 5 && this.p.password === this.p.password2)
    },
    taskDateStr: function () {
      return moment(this.taskDate).format('YYYY-MM-DD')
    },
    users: function () {
      return this.$store.state.users.filter(u => u.value)
    },
    customers: function () {
      return this.$store.state.customers.filter(u => u.value)
    },
    me: function () {
      return this.$store.state.me
    }
  },
  methods: {
    async getCReport () {

      let csvContent = (await axios.get(`/api/summary?from=${this.cFrom}&to=${this.cTo}`)).data
      console.log(csvContent)

      var blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})

      var link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `report-${this.cFrom}-${this.cTo}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

    },
    getIcon (idx) {
      if (idx === 0) return this.homeIcon
      else return this.meetingIcon
    },
    async showDistance (u) {
      //fetch meeting for that day, then show how the distance is calculated
      this.userTravelTitle = `Distance Traveled by ${u.user.name}`
      let r = (await axios.get(`/api/meeting?id=${u.user.id}&date=${this.daySelect}&days=${this.days}`)).data
      this.travel = r.latLngs
      this.latlng = u.user.location
      this.homeLoc = r.homeLoc
      this.meetings = r.meetings
      this.distance = r.distance
      console.log('travel', r.latLngs, u.user.location)

      this.$bvModal.show('user-travel')
      setTimeout(() => {
        this.$refs.map3.mapObject.invalidateSize()
      }, 500)
    },
    showLocation (u) {
      this.latlng = u.location
      this.userLocationTitle = `Last Known Location of ${u.user.name}`
      this.$bvModal.show('user-location')
      setTimeout(() => {
        this.$refs.map2.mapObject.invalidateSize()
      }, 500)
    },
    hasLocation (u) {
      return u.location && u.location.length === 2
    },
    scoreClass (u) {
      if (u.score < 40) return 'bg-danger text-white pt-1 pb-1'
      if (u < 60) return 'bg-warning text-black  pt-1 pb-1'
      return 'bg-white text-black  pt-1 pb-1'
    },
    makeScore (u) {
      return Number(u).toFixed(0)
    },
    tagStyle (tn) {
      let bgColor = this.$store.state.colorMap[tn] || '#829356'
      return `font-size: small;text-transform: uppercase;background-color: ${bgColor};color: #FFFFFF;width: 60px;cursor: pointer`

    },
    tagCount (tn, u) {
      let ll = (u.latest_tags || []).filter(tt => tt.tag === tn)
      return ll.length === 0 ? 'NA' : ll[0].tag_count
    },
    showStatus (u) {
      this.selectedUser = u
      this.$bvModal.show('wa-status')
    },
    async askUserToWAConnect () {
      await axios.post('/api/admin/send-message', {
        id: this.selectedUser.user.id,
        phone: this.selectedUser.user.phone,
        message: `Kindly Connect WhatsApp, open link in Computer and Scan QR Code. https://app.sygnal.in/connect/${this.selectedUser.user.phone}`
      })
      alert('Message Sent to User')
    },
    async sendAppUpdate (user) {
      if (confirm('Send App Update Message to User?')) {
        await axios.post('/api/admin/send-message', {
          id: user.id,
          phone: user.phone,
          message: `Kindly Update to New Version of App. http://build.sygnal.in/sygnal.apk`
        })
        alert('Message Sent to User')
      }
    },
    getStyle (idx) {
      return `border-color:${this.colors[idx]};border-style: solid;border-width: 0px 0px 10px 0px;height:100px`
    },
    async loadDashboard () {
      if (this.days > -1)
        await this.loadChart()
    },
    async refreshDashboard () {
      this.interval = setInterval(async () => {
        await this.loadChart()
      }, 60 * 1000)

    },
    async loadChart () {
      this.chartData = []
      let resp = await axios.get(`/api/dashboard?days=${this.days}&date=${this.daySelect}`)

      function fmtDate (w) {
        if (!w) return 'NA'
        let m = moment(w.replace('Z', ''))
        if (!m.isValid() || w.indexOf('0001') > -1) return 'NA'
        return m.fromNow()
      }

      this.reports = resp.data.highlight
      let scores = resp.data.scores || []
      this.userStatus = resp.data.status.map(r => {
        r['last_sms'] = fmtDate(r.last_sms)
        r['last_call'] = fmtDate(r.last_call)
        r['last_wa'] = fmtDate(r.last_wa)
        r['last_seen'] = fmtDate(r.last_seen)
        r['last_tag'] = fmtDate(r.last_tag)
        r['last_location'] = fmtDate(r.last_meeting)
        let ts = scores.filter(s => s.user_id === r.user.id)
        if (ts.length > 0) {
          r['score'] = ts[0].final_score
          if (ts[0].distance && ts[0].distance > 0) {
            r['distance'] = ts[0].distance
          }
        }
        return r
      })

      this.chartData = scores.map(us => {
        return [us.user.name, us.final_score]
      })

    },
    modalClosed () {
      this.visible = false
      this.qrCodeInitiated = false
    }
  }
}
</script>
<style scoped>
.card-width {
  width: 5em;
}

.highlight.card {
  width: 12em;
}

.user.card {
  width: 10em;
}

.highlight .card-body {
  padding: .5em;
}

@media (max-width: 576px) {
  .highlight.card {
    width: 100%;
    margin-top: 10px;
  }

  .user.card {
    width: 100%;
    margin-top: 10px;
  }

}

@media (min-width: 576px) {

  .card-columns.column-5 {

  }
}

.report-count {
  font-size: 1.2rem;
}

.list-group-item {
  padding: .5em;
}
</style>