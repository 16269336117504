<template>
  <div class="d-flex flex-row justify-content-between align-items-start">
    <div class="typ mr-3 text-center  d-flex justify-content-center flex-column"
         style="width: 60px">

      <div class="typ-img text-center d-flex justify-content-center flex-column align-items-center mb-1"
           style="font-size: 2rem;">

        <img src="@/assets/placeholder.svg"
             style="width: 24px"
             v-if="c.message_type === 'MEETING'"/>

        <img src="@/assets/flaticon-svg/042-call-missed-out.svg"
             style="width: 24px"
             v-if="!c.incoming && c.message_type === 'CALL' && c.duration === 0"/>

        <img src="@/assets/flaticon-svg/043-call-missed-in.svg"
             style="width: 24px"
             v-if="c.incoming && c.message_type === 'CALL' && c.duration === 0"/>

        <img src="@/assets/flaticon-svg/047-call-in.svg"
             style="width: 24px"
             v-if="c.incoming && c.message_type === 'CALL' && c.duration > 0"/>

        <img src="@/assets/flaticon-svg/047-call.svg"
             style="width: 24px"
             v-if="!c.incoming && c.message_type === 'CALL' && c.duration > 0"/>

        <i class="icon icon-download2 text-success" v-if="c.message_type === 'SMS' && c.incoming"></i>
        <i class="icon icon-upload2 text-info" v-if="c.message_type === 'SMS' && !c.incoming"></i>
        <i class="fab fa-whatsapp"
           v-if="c.message_type === 'WHATSAPP'"
           :class="{'text-info' : !c.incoming, 'text-success': c.incoming}"></i>
      </div>
      <div class="typ-tag mt-1 p-1" :style="style">
        {{ $store.state.dispMap[c.tag] }}
      </div>
    </div>

    <div class="d-flex  flex-fill  flex-column">
      <div class="cn d-flex flex-column flex-md-row justify-content-between" v-if="!showSP">
        <div v-if="c.contact">{{ c.contact.name }} ({{ c.contact.phone }})</div>
        <div v-else class="text-muted">Contact Not Selected</div>
        <div class="mt-1">{{ at }}</div>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-start align-items-center align-content-center" v-else>
        <b-avatar size="1rem" :text="c.user.name.substring(0,2)" :src="c.user.image"></b-avatar>
        <div class="ml-1">{{ c.user.name }}</div>
      </div>


      <div class="content text-muted mt-3 mt-md-0">
        <div v-if="c.message_type === 'CALL'">
          <div class="" v-if="c.duration === 0">
            <span v-if="!c.incoming">Dialed</span>
            <span v-else>Received</span>
            Missed Call
          </div>
          <span class="" v-else>Call for {{ c.duration }} Seconds</span>
        </div>
        <div v-else>
          {{ c.message }}
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2" v-if="attachmentUrl">
          <a href="#" @click.prevent="showAttachments" class="btn btn-primary btn-sm">
            <font-awesome-icon :icon="['far','paperclip']"></font-awesome-icon>
          </a>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2" v-if="c.message_type === 'MEETING'">
          <a href="#" @click.prevent="showMap(c)" class="btn btn-info btn-sm">
            <font-awesome-icon :icon="['far','location']"></font-awesome-icon>
          </a>
        </div>

      </div>

      <div class="list-group" v-if="c.meeting_fields">
        <div class="list-group-item" v-for="mf of c.meeting_fields" :key="mf.id">
          {{ mf.role_form.field_name }} : {{ mf.form_value }}
        </div>
      </div>
    </div>
    <b-modal :id="id" size="xl" title="View Attachment">
      <iframe :src="attachmentUrl" width="100%" height="700"></iframe>
    </b-modal>
    <b-modal :id="'loc-'+id" size="xl"
             @shown="modalShown"
             title="View Meeting Location">
      <div class="div" style="width:100%; height: 100%;min-height: 300px;min-width: 300px;">
        <l-map
            ref="map"
            style="height: 80%; width: 100%;min-height: 400px"
            :zoom="zoom"
            :center="center">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet'

export default {
  name: 'DisplayContent',
  components: {LMap, LTileLayer, LMarker},
  props: ['c', 'salesPerson'],
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoom: 16,
    center: [47.413220, -1.219482],
    markerLatLng: [47.413220, -1.219482],
  }),
  computed: {
    showSP() {
      return this.salesPerson === "true"
    },
    style() {
      let bgColor = this.$store.state.colorMap[this.c.tag] || '#829356';
      return `font-size: xx-small;text-transform: uppercase;background-color: ${bgColor};color: #FFFFFF`
    },
    id() {
      return 'attachment-' + Math.random()
    },
    attachmentUrl() {
      if (!this.c) return null
      if (!this.c.attachments) return null
      if (this.c.attachments.length === 0) return null

      let img = this.c.attachments[0].replace('/var/tmp/wa-files/', '');
      img = img.replace('/var/tmp/wa_images/', '')

      let split = img.split('/')
      let file = split[1]
      let user = split[0]
      return `${process.env.VUE_APP_API}/public/attachment?id=${file}&user=${user}`
    },
    at() {
      return this.c.sent_at
    }
  },
  methods: {
    showAttachments() {
      this.$bvModal.show(this.id)
    },
    showMap(c) {
      setTimeout(() => {
        this.center = [c.lat, c.lng]
        this.markerLatLng = [c.lat, c.lng]
        this.$refs.map.mapObject.invalidateSize();
      }, 100)
      this.$bvModal.show('loc-' + this.id)
    },
    modalShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.map.mapObject.invalidateSize();
      }, 100);
    }
  }
}
</script>

<style scoped>

</style>