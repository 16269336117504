<template>

  <b-navbar toggleable="lg" type="dark" variant="faded">
    <b-navbar-brand href="#">
      <img src="@/assets/logo.png"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">

        <b-nav-item class="nav-link" exact-active-class="active" to="/">
          <font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
          Dashboard
        </b-nav-item>
        <b-nav-item class="nav-link" exact-active-class="active" to="/employee-reports">
          <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
          <span class="ml-1" v-if="$store.state.tenantAdmin || $store.state.manager">Employee</span>
          <span class="ml-1" v-else>My Communications</span>
        </b-nav-item>

        <b-nav-item class="nav-link" exact-active-class="active" to="/customer-reports">
          <font-awesome-icon :icon="['fas', 'user-crown']"></font-awesome-icon>
          Contact
        </b-nav-item>

        <b-nav-item class="nav-link" exact-active-class="active" :href="todayReport" target="_blank">
          <font-awesome-icon :icon="['fas', 'calendar']"></font-awesome-icon>
          Report
        </b-nav-item>

        <b-nav-text class="nav-link">
          <a href="tel:+919591876072" class="nav-link text-white">
            <font-awesome-icon :icon="['fas', 'phone']"></font-awesome-icon>
            +91 9591876072
          </a>
        </b-nav-text>
        <b-nav-item-dropdown right v-if="$store.state.isLoggedIn" class="nav-link">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <font-awesome-icon :icon="['far','cogs']"></font-awesome-icon>
            <em>Settings</em>
          </template>
          <b-dropdown-item to="/settings" v-if="$store.state.tenantAdmin">
            <font-awesome-icon :icon="['far','cogs']"></font-awesome-icon>
            Settings
          </b-dropdown-item>
          <b-dropdown-item to="/admin" v-if="$store.state.superAdmin">
            <font-awesome-icon :icon="['far','users-crown']"></font-awesome-icon>
            Admin
          </b-dropdown-item>
          <b-dropdown-item to="/logout">
            <font-awesome-icon :icon="['far','sign-out']"></font-awesome-icon>
            Sign Out
          </b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>


    </b-collapse>
  </b-navbar>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AppHeader',
  data () {
    return {}
  },
  computed: {
    todayReport () {
      let tenantId = this.$store.state.me.tenant_id
      return `/report?date=${moment().format('YYYY-MM-DD')}&tenant_id=${tenantId}`
    }
  },
  updated () {

  },
  methods: {
    getLastUpdate: function () {

    }
  }

}
</script>

<style scoped>
.navbar-brand img {
  max-height: 32px;
}
</style>
