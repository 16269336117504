<template>
  <div id="app">
    <app-header v-if="$store.state.isLoggedIn"></app-header>
    <vue-progress-bar></vue-progress-bar>
    <div class="container-fluid mt-3">
      <router-view/>
    </div>
  </div>
</template>

<script>
    import AppHeader from './components/AppHeader.vue'

    export default {
        name: 'App',
        components: {
            AppHeader
        },
        async created() {
            if(this.$route.fullPath !== '/login' && this.$route.fullPath !== '/logout')
              await this.checkLogin()
        },
        methods: {
            async checkLogin() {
                try {
                    await this.$store.dispatch('checkUserLogin')
                    await this.loadUsers()
                } catch (e) {
                    console.log('error in login', e)
                    await this.$router.push("/login")
                }
            },
            async loadUsers() {
                await this.$store.dispatch('loadUsers')
                await this.loadCustomers()
            },
            async loadCustomers() {
                await this.$store.dispatch('loadCustomers')
            }
        }
    }
</script>

<style>

  #app {
    background-image: url("./assets/bg.webp");
    min-height: 100vh;
    background-size: contain;
  }

  .page {
    min-height: 100vh;
    padding: 30px;
  }

  @media (max-width: 768px) {
    .page {
      padding: 10px;
    }
  }
</style>
