<template>
  <div :class="'card ' + cls">
    <div class="card-header">
      <div
        class="d-flex w-100 justify-content-center justify-content-md-between align-content-center align-items-center flex-column flex-md-row">
        <h6 class="mb-1 d-flex justify-content-md-center align-items-center">
          <i class="fa fa-phone" v-if="t.task_type === 'CALL'"></i>
          <i class="fa fa-envelope" v-if="t.task_type === 'EMAIL'"></i>
          <i class="fa fa-users-class" v-if="t.task_type === 'MEET'"></i>
          <i class="fa fa-people-arrows" v-if="t.task_type === 'E-MEET'"></i>
          <span class="ml-2" v-if="t.contact">{{t.contact.name}}</span>
        </h6>
        <span class="mt-2 mt-md-0">
            <i class="fa fa-clock"></i> {{t.created_at}}
        </span>
      </div>

    </div>
    <div class="card-body">
      <div class="list-group">
        <div class="list-group-item">
          Created By: <em>{{t.user.name}}</em>
        </div>
        <div class="list-group-item">
          Assigned To: <em>{{t.assigned_to.name}}</em>
        </div>
        <div class="list-group-item" v-if="t.notes">
          {{t.notes}}
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-start">
        <div class="due">
          <i class="fa fa-alarm-clock"></i> {{t.task_due}}
        </div>
        <div class="action flex-fill d-flex justify-content-end" v-if="canClose">
          <b-button-group size="sm">

            <b-button variant="danger" @click="cancelTask">
              <font-awesome-icon :icon="['fas', 'times-hexagon']"></font-awesome-icon>
            </b-button>
            <b-button variant="success" @click="completeTask">
              <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <b-modal :id="modalId" :title="title" @ok="saveTask">
      <b-list-group>
        <b-list-group-item>
          Task : {{task.task_type}}
        </b-list-group-item>
        <b-list-group-item>
          Customer : {{task.contact.name}} ({{task.contact.phone}})
        </b-list-group-item>
        <b-list-group-item>
          Created : {{task.user.name}}
        </b-list-group-item>
        <b-list-group-item>
          Assigned To : {{task.assigned_to.name}}
        </b-list-group-item>
        <b-list-group-item>
          Note : {{task.notes}}
        </b-list-group-item>
        <b-list-group-item v-for="(c, idx) of task.comments" :key="idx">
          {{c}}
        </b-list-group-item>
        <b-list-group-item>
          <b-form-group label="Comment">
            <b-form-textarea v-model="comment"></b-form-textarea>
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>

    import {Task} from "@/models";
    import axios from 'axios'

    export default {
        name: "Task",
        props: {
            t: {
                type: Object,
                required: true
            },
            cls: {
                type: String
            },
            hideCreatedBy: {
                type: Boolean,
                default: false
            },
            hideAssignedTo: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                title: '',
                task: new Task(),
                comment: ''
            }
        },
        computed: {
            modalId() {
                return 'task-modal-' + this.t.id
            },
            canClose() {
                return this.$store.state.me.id === this.t.user_id
                    || this.$store.state.me.id === this.t.assigned_to_id
                    || this.$store.state.me.tenentAdmin
            }
        },
        methods: {
            async cancelTask() {
                this.title = 'Cancel Task'
                this.task = Object.assign({}, this.t)
                this.task.task_status = 'CANCELLED'
                this.$bvModal.show(this.modalId)
            },
            async completeTask() {
                this.title = 'Complete Task'
                this.task = Object.assign({}, this.t)
                this.task.task_status = 'COMPLETED'
                this.$bvModal.show(this.modalId)
            },
            async saveTask() {
                await axios.put('/api/task', {
                    task_id: this.task.id,
                    task_status : this.task.task_status,
                    task_comment : this.comment
                })
                this.$bvModal.hide(this.modalId)
                this.$emit('task-updated')
            }
        }
    }
</script>