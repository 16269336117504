import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueQrcode from '@chenfengyuan/vue-qrcode';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/icomoon.css'
import '@/assets/flaticon.css'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import VueProgressBar from 'vue-progressbar'
import VueCountdown from '@chenfengyuan/vue-countdown';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'

library.add(fab)
library.add(far)
library.add(fas)
dom.watch()
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.config.productionTip = false

Vue.component(VueCountdown.name, VueCountdown);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueAxios, axios)

Vue.use(Chartkick.use(Chart))
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const options = {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '30px',
    transition: {
        speed: '0.1s',
        opacity: '0.6s',
        termination: 6000
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}
Vue.use(VueProgressBar, options)

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.headers.common['Authorization'] = `Bearer: ${localStorage.getItem('AuthToken')}`

console.log('API = ', process.env)
let app = new Vue({
    el: '#app',
    router,
    store,
    components: {App},
    template: '<App/>'
})


router.beforeEach((to, from, next) => {
    if (store.state.isLoggedIn) {
        // do nothing
        console.log('Allow', to.path)
    } else {
        if (to.path !== '/login') {
            console.log('Deny', to.path)
            next('login')
        }
    }
    next()
})

// Intercept all requests
axios.interceptors.request.use(
    (config) => {
        // do something before sending requests
        app.$Progress.start()
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// Intercept all responses
axios.interceptors.response.use(
    (response) => {
        // when success from server
        app.$Progress.finish()
        return response
    },

    (error) => {
        // when error from server
        app.$Progress.fail()
        return Promise.reject(error)
    }
)

