import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'

let jwtDecode = require('jwt-decode')

Vue.use(Vuex)

let key = 'AuthToken';
export default new Vuex.Store({
    state: {
        count: 0,
        userId: 0,
        tenantId: 0,
        roleId: 0,
        users: [],
        customers: [],
        colorMap: {
            'CUSTOMER': '#BCA136',
            'INTERNAL': '#9A2617',
            'SPAM' : '#C2571A',
            'QUALIFIED_LEAD': '#107896',
            'PERSONAL': '#093145',
            'UNKNOWN': '#829356'
        },
        dispMap: {
            'CUSTOMER': 'Customer',
            'INTERNAL': 'Internal',
            'SPAM' : 'Spam/DND',
            'QUALIFIED_LEAD': 'Lead',
            'PERSONAL': 'Personal',
            'UNKNOWN': 'Unknown',
            '': 'Unknown'
        },
        dayOptions: [
            {text: 'Today', value: 0},
            {text: 'Yesterday', value: 1},
        ],
        taskTypes: [
            {text: "All Types", value: ""},
            {text: "Followup Call", value: "CALL"},
            {text: "Face to Face Meeting", value: "MEET"},
            {text: "E-Meeting", value: "E-MEET"},
            {text: "Send an EMail", value: "EMAIL"},
        ],
        taskStatus: [
            {text: "All Status", value: ""},
            {text: "Completed", value: "COMPLETED"},
            {text: "Assigned", value: "ASSIGNED"},
            {text: "Cancelled", value: "CANCELLED"},
        ],
        superAdmin: false,
        tenantAdmin: false,
        manager: false,
        authToken: localStorage.getItem(key),
        isLoggedIn: localStorage.getItem(key) != null,
        taskWindow: false,
        me: {connection_lost: false}
    },

    mutations: {
        increment(state, by) {
            state.count += by
        },
        saveUser(state, users) {
            let lUsers = (users || []).map(u => {
                return {
                    text: `${u.name} (${u.phone})`,
                    value: u.id,
                    id: u.id,
                    phone: u.phone,
                    name: u.name,
                    role: u.role
                }
            })
            lUsers.unshift({
                text: "All Users",
                value: null
            })
            state.users = lUsers
        },
        saveCustomer(state, users) {
            let contacts = (users || []).map(u => {
                return {
                    text: `${u.name} (${u.phone})`,
                    value: u.id,
                    id: u.id,
                    phone: u.phone,
                    name: u.name
                }
            })
            contacts.unshift({
                text: "All Customers",
                value: null
            })
            state.customers = contacts
        },
        loggedIn(state, {me, at}) {
            state.authToken = at
            state.me = me
            state.isLoggedIn = true

            let decoded = jwtDecode(at)
            state.userId = decoded['UserId']
            state.tenantId = decoded['TenantId']
            state.roleId = decoded['RoleId']
            state.superAdmin = decoded['SuperAdmin']
            state.tenantAdmin = decoded['TenantAdmin']
            state.manager = decoded['Manager']
            console.log('decoded', decoded)
            axios.defaults.headers.common['Authorization'] = `Bearer: ${at}`
            localStorage.setItem(key, at)
        },
        logout(state) {
            state.authToken = null
            state.isLoggedIn = false
            state.userId = null
            state.tenantId = null
            state.roleId = null
            state.superAdmin = false
            state.tenantAdmin = false
            localStorage.removeItem(key)
        },
        showTaskWindow(state) {
            state.taskWindow = true
        },
        hideTaskWindow(state) {
            state.taskWindow = false
        }
    },
    actions: {
        loadUsers(context) {
            return new Promise(((resolve, reject) => {
                axios.get("/api/user")
                    .then(resp => {
                        context.commit("saveUser", resp.data)
                        resolve()
                    }, err => {
                        reject(err)
                    })
            }))
        },
        loadCustomers(context) {
            return new Promise(((resolve, reject) => {
                axios.get("/api/customers")
                    .then(resp => {
                        context.commit("saveCustomer", (resp.data || [])
                            .map(c => {
                                c['name'] = _.truncate(c['name'], 10)
                                return c
                            }))
                        resolve()
                    }, err => {
                        reject(err)
                    })
            }))
        },
        checkUserLogin(context) {
            let at = localStorage.getItem(key)
            return new Promise((resolve, reject) => {
                if (at) {
                    let config = {
                        headers: {
                            Authorization: at
                        }
                    };

                    axios.get('/api/me', config).then(me => {
                        context.commit('loggedIn', {at:at, me: me.data})
                        resolve()
                    }, err => {
                        context.commit('logout')
                        console.error('error in /api/me', err)
                        reject(Error('AuthToken is Invalid'))
                    })
                } else {
                    reject(Error('authToken missing'))
                }
            })
        },
        closeTask(context) {
            return new Promise((resolve => {
                context.commit('hideTaskWindow')
                resolve()
            }))
        },
        showTask(context) {
            return new Promise((resolve => {
                context.commit('showTaskWindow')
                resolve()
            }))
        }
    }
})