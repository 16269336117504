<template>
    <section class="page bg-white">
        <b-tabs content-class="mt-3">
            <b-tab title="Users">
                <div class="row">
                    <div class="col-12">
                        <h4>Users</h4>
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>EMail</th>
                                <th>Phone</th>
                                <th>Last SMS</th>
                                <th>Last Call</th>
                                <th>Last WhatsApp</th>
                                <th>Whatsapp Connection Status</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="t of users" :key="t.user.id">
                                <td>{{t.user.name}}</td>
                                <td>{{t.user.e_mail}}</td>
                                <td>{{t.user.phone}}</td>
                                <td>
                                    <span v-if="t.last_sms === 'NA'" class="text-danger">Not Synced</span>
                                    <span v-if="t.last_sms !== 'NA'">{{t.last_sms}}</span>

                                </td>
                                <td>
                                    <span v-if="t.last_call === 'NA'" class="text-danger">Not Synced</span>
                                    <span v-if="t.last_call !== 'NA'">{{t.last_call}}</span>
                                </td>
                                <td>
                                    <span v-if="t.last_wa === 'NA'" class="text-danger">Not Synced</span>
                                    <span v-if="t.last_wa !== 'NA'">{{t.last_wa}}</span>
                                </td>
                                <td>
                                    <i class="fa fa-check text-success" v-if="t.wa_status"></i>
                                    <i class="fa fa-times text-danger" v-if="!t.wa_status"></i>
                                </td>
                                <td>
                                    <b-button variant="primary"
                                              @click="connectWA(t.user)">Connect <i class="fab fa-whatsapp"></i></b-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-tab>
            <b-modal ref="qr-code" id="qr-code" hide-footer title="Scan Qr Code" @hidden="modalClosed">
                <div class="d-flex flex-column justify-content-center align-content-center text-center">
                    <h4>Register Whatsapp for {{user.name}}</h4>
                    <div class="d-flex justify-content-center flex-column align-content-center text-center">
                        <div>
                            <qrcode :value="qrCode"></qrcode>
                        </div>
                        <div>
                            <countdown :time="timeRemaining">
                                <template slot-scope="props">Time Remaining -> {{props.hours}}:{{props.minutes}}:{{ props.seconds }} seconds.</template>
                            </countdown>
                        </div>
                    </div>
                </div>
            </b-modal>
        </b-tabs>
    </section>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    import {logError, Tenant, User} from "../models";

    export default {
        name: 'Status',
        data() {
            return {
                users: [],
                roles: [],
                tenant: new Tenant(),
                user: new User(),
                qrCode: '',
                visible: false,
                visibleAt: null,
                visibleTill: null,
                timeRemaining: null,
                interval: null
            }
        },
        destroyed() {
            clearInterval(this.interval)
        },
        created() {
            axios.get('/api/role')
                .then(resp => {
                    this.roles = resp.data || []
                }, logError)

            this.loadUsers()

            this.interval = setInterval(() => {
                //check
                if (this.visible) {
                    let now = new Date().getTime();
                    if (now > this.visibleTill) {
                        this.$bvModal.hide('qr-code')
                    }
                }
            }, 1000)
        },
        methods: {
            connectWA(t) {
                this.user = t
                axios.put('/api/admin/connect', {
                    user_id: t.id,
                    mobile: t.phone
                }).then(resp => {
                    this.qrCode = resp.data.qrCode
                    this.$bvModal.show('qr-code')
                    //hide modal after x seconds
                    this.visible = true
                    this.visibleAt = new Date().getTime()
                    this.visibleTill = resp.data.expiry * 1000
                    this.timeRemaining = 110*1000;
                })
            },
            loadUsers() {

                function fmtDate(w) {
                    let m = moment(w);
                    if(!m.isValid() || w.indexOf("0001") > -1 ) return "NA"
                    return m.format("DD/MMM/YYYY hh:mm a");
                }

                axios.get('/api/admin/user-status')
                    .then(resp => {
                        this.users = (resp.data || []).map(r => {
                            // c['sent_at'] = moment(c.sent_at).format("DD/MMM/YYYY hh:mm a")
                            r['last_sms'] = fmtDate(r.last_sms)
                            r['last_call'] = fmtDate(r.last_call)
                            r['last_wa'] = fmtDate(r.last_wa)
                            return r;
                        })
                    }, logError)
            },
            saveUser() {

                axios.post('/api/admin/user', this.user)
                    .then(() => {
                        this.loadUsers()
                    }, logError)
            },
            modalClosed() {
                this.visible = false
            }
        }
    }
</script>
<style scoped>
    html,
    body {
        height: 100%;
    }

    body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5;
    }

    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }

    .form-signin .form-control:focus {
        z-index: 2;
    }

    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

</style>