<template>
  <section class="page bg-white">
    <b-tabs content-class="mt-3">
      <b-tab title="Users">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <h4>List of Users</h4>

            <b-form-group label="Filter">
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selected"
                  name="flavour-2"
              >
                <b-form-checkbox value="admin">Show Admin</b-form-checkbox>
                <b-form-checkbox value="manager">Show Manager</b-form-checkbox>
                <b-form-checkbox value="deactive">Show Deactive</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <b-button @click.prevent="newUser" variant="primary">
              <font-awesome-icon icon="user-plus"></font-awesome-icon>
              New User
            </b-button>
          </div>
          <div class="d-flex flex-wrap mt-3">

            <div class="card mr-md-2 mb-2" v-for="t of filteredUsers" :key="t.id"
                 v-bind:class="{'bg-warning': !t.enabled}">
              <div class="card-header">{{ t.name }}</div>
              <div class="card-body">
                <div class="list-group">
                  <div class="list-group-item">
                    <div class="d-flex flex-column">
                      <div class="mb-2">
                        <font-awesome-icon icon="user-crown"></font-awesome-icon>
                        {{ t.role.name }}
                      </div>
                      <div class="list-group list-group-flush">
                        <div class="list-group-item" v-for="i of t.sub_users" :key="i">
                          <i class="fa fa-user"></i>
                          <span class="ml-2">{{ getNameOfUser(i) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <font-awesome-icon icon="envelope"></font-awesome-icon>
                    {{ t.e_mail }}
                  </div>
                  <div class="list-group-item">
                    <font-awesome-icon icon="phone"></font-awesome-icon>
                    {{ t.phone }}
                  </div>
                  <div class="list-group-item" v-if="t.location_addr" style="max-width: 300px">
                    <font-awesome-icon icon="location"></font-awesome-icon>
                    {{ t.location_addr }}
                  </div>
                  <div class="list-group-item">
                    <b-button @click="showUserLocation(t)" v-if="t.location" variant="outline-secondary">
                      <font-awesome-icon icon="location"></font-awesome-icon>
                      View Location
                    </b-button>
                    <span v-else><font-awesome-icon icon="location-slash"></font-awesome-icon> Location Not Set</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex align-content-center align-items-center">
                  <b-button-group size="sm" class="ml-3">


                    <b-button variant="primary" @click.prevent="editUser(t)">
                      <font-awesome-icon icon="pencil"></font-awesome-icon>
                      Edit
                    </b-button>
                    <b-button variant="danger" @click.prevent="toggle(t)" v-if="t.enabled">
                      <font-awesome-icon icon="times"></font-awesome-icon>
                      Disable
                    </b-button>
                    <b-button variant="success" @click.prevent="toggle(t)" v-if="!t.enabled">
                      <font-awesome-icon icon="check"></font-awesome-icon>
                      Enable
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal id="onboard-user" hide-footer :title="onboardTitle" size="xl">
      <section>
        <b-button v-b-toggle.step-1 class="m-1" :disabled="currentStep !== 1">1. Install App</b-button>
        <b-button v-b-toggle.step-2 class="m-1" :disabled="currentStep !== 2">2. Login to App</b-button>
        <b-button v-b-toggle.step-3 class="m-1" :disabled="currentStep !== 3">3. Allow Permissions</b-button>
        <b-button v-b-toggle.step-4 class="m-1" :disabled="currentStep !== 4">4. Select Work Number Sim</b-button>
        <b-button v-b-toggle.step-5 class="m-1" :disabled="currentStep !== 5">5. Login to Desktop</b-button>
        <b-button v-b-toggle.step-6 class="m-1" :disabled="currentStep !== 6">6. Connect WhatsApp</b-button>
        <b-collapse id="step-1" :visible="currentStep === 1" class="mt-3">
          <b-card>
            Install App from Downloading
            <pre>https://build.e-waybill.in/app-release.apk</pre>
            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="gotoStep(2)">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  App Installed
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
        <b-collapse id="step-2" :visible="currentStep === 2" class="mt-3">
          <b-card>
            Open Sygnal App and Login using Mobile number & Otp

            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="gotoStep(3)">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  Login Complete
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
        <b-collapse id="step-3" :visible="currentStep === 3" class="mt-3">
          <b-card>

            Click Allow for All Permissions

            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="gotoStep(4)">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  Permissions Given
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
        <b-collapse id="step-4" :visible="currentStep === 4" class="mt-3">
          <b-card>

            if Dual Sim Phone, Select Work Sim
            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="gotoStep(5)">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  Sim Selected
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
        <b-collapse id="step-5" :visible="currentStep === 5" class="mt-3">
          <b-card>

            In Desktop, open
            <pre>https://app.sygnal.in</pre>
            and Login with Mobile and OTP
            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="gotoStep(6)">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  Desktop Login Okay
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
        <b-collapse id="step-6" :visible="currentStep === 6" class="mt-3">
          <b-card>

            Open WhatsApp, select WhatsApp web and Scan QR Code shown in Desktop
            <template v-slot:footer>
              <div class="d-flex justify-content-end">
                <b-button variant="success" @click.prevent="completeOnboarding()">
                  <font-awesome-icon icon="check"></font-awesome-icon>
                  WhatsApp Connected
                </b-button>
              </div>
            </template>
          </b-card>
        </b-collapse>
      </section>

    </b-modal>
    <b-modal id="user-location" hide-footer :title="userLocationTitle">
      <div class="d-flex flex-column justify-content-center">

        <l-map
            ref="map2"
            style="height: 300px; width: 300px;"
            :zoom="zoom"
            :center="center">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker @update:lat-lng="updateMap" :lat-lng="center"></l-marker>
        </l-map>
      </div>
    </b-modal>
    <b-modal id="user-add" hide-footer :title="userAddTitle">
      <b-form>
        <b-form-group label="Name">
          <b-form-input v-model="user.name"></b-form-input>
        </b-form-group>
        <b-form-group label="EMail">
          <b-form-input v-model="user.e_mail" type="email"></b-form-input>
        </b-form-group>
        <b-form-group label="Phone">
          <b-form-input v-model="user.phone" type="tel"></b-form-input>
        </b-form-group>

        <b-form-group label="Role">
          <b-form-select v-model="user.role_id"
                         text-field="name"
                         value-field="id"
                         :options="roles"></b-form-select>
        </b-form-group>

        <b-form-group label="Number of Days to Sync (older data)" v-if="!isManagerRole && !isAdminUser">
          <b-form-input v-model="user.sync_back" type="number"></b-form-input>
        </b-form-group>

        <b-form-group label="Sub Users" v-if="isManagerRole">
          <b-form-select v-model="user.sub_users"
                         text-field="name"
                         value-field="id"
                         multiple
                         :options="nonAdminUsers"></b-form-select>
        </b-form-group>
        <b-form-group label="Password"
                      v-if="isAdminUser || isManagerRole"
                      description="Password is Required for Admin/Managers">
          <b-form-input v-model="user.password" required></b-form-input>
        </b-form-group>

        <b-input-group prepend="Lat,Lng" class="mt-3 mb-3">
          <b-form-input v-model="latLng"></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-success" @click="setLatLng">Set</b-button>
          </b-input-group-append>
        </b-input-group>


        <b-form-group label="Home Location"
                      v-if="!isAdminUser && !isManagerRole"
                      description="Home/Base Location for this User">
          <l-map
              ref="map"
              style="height: 300px; width: 300px;"
              :zoom="zoom"
              :center="center">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :draggable="true"
                      @update:lat-lng="updateMap"
                      :lat-lng="center"></l-marker>
          </l-map>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button @click="saveUser">
            <i class="fa fa-save"></i> Save User
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import { Tenant, User } from '../models'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

export default {
  name: 'Admin',
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      users: [],
      selected: [],
      roles: [],
      tenant: new Tenant(),
      user: new User(),
      ou: new User(),
      currentStep: 0,
      value: 0,
      value1: 0,
      debug: '',
      latLng: '',
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  computed: {
    userRoles () {
      return this.roles.filter(r => !r.manager && !r.super_admin && !r.tenant_admin)
    },
    nonAdminUsers () {
      return this.users.filter(u => !u.role.super_admin && !u.role.tenant_admin && !u.role.manager)
    },
    filteredUsers () {
      let us = this.users
      if (this.selected.indexOf('admin') === -1) {
        //remove admin
        us = us.filter(u => !u.role.super_admin && !u.role.tenant_admin)
      }
      if (this.selected.indexOf('manager') === -1) {
        //remove manager
        us = us.filter(u => !u.role.manager)
      }
      if (this.selected.indexOf('deactive') === -1) {
        //remove deactive
        us = us.filter(u => u.enabled)
      }
      return us
    },
    isManagerRole () {
      if (this.user.role_id) {
        let rs = this.roles.filter(r => r.id === this.user.role_id)
        if (rs.length > 0) {
          return rs[0].manager
        }
      }
      return false
    },
    onboardTitle () {
      return `Onboard ${this.ou.name}`
    },
    userAddTitle () {
      return this.user.id == null ? 'New User' : `Edit ${this.user.name}`
    },
    userLocationTitle () {
      return this.user.id == null ? 'New User' : `Home Location of ${this.user.name}`
    },
    isAdminUser () {
      if (!this.user.role_id) return false
      let f = this.roles.filter(r => r.id === this.user.role_id)
      return f.length === 0 ? false : f[0]['tenant_admin']
    },
    center () {
      return this.user.location ? this.user.location : [12, 77]
    },
  },
  async created () {
    this.roles = ((await axios.get('/api/role')).data).map(r => {
      r.value = 50
      r.value1 = 50
      return r
    })
    await this.loadUsers()
  },
  methods: {
    isUser (u) {
      return !(u.role.manager || u.role.tenant_admin || u.role.super_admin)
    },
    setLatLng () {
      console.log('set', this.latLng)
      let xp = this.latLng.split('x')
      let cp = this.latLng.split(',')
      if (xp.length !== 2 && cp.length !== 2) return
      if (cp.length === 2) {
        this.user.location = [+(cp[0].trim()), +(cp[1].trim())]
      }
      if (xp.length === 2) {
        this.user.location = [+(xp[0].trim()), +(xp[1].trim())]
      }
    },
    updateMap (latLng) {
      console.log('move', latLng)
      this.user.location = [latLng.lat, latLng.lng]
    },
    async loadUsers () {
      this.users = (await axios.get('/api/admin/user')).data || []
    },
    async saveUser () {
      await axios.post('/api/admin/user', this.user)
      this.$bvModal.hide('user-add')
      await this.loadUsers()
    },
    async toggle (u) {
      await axios.put(`/api/admin/user?user_id=${u.id}`)
      await this.loadUsers()
    },
    async newUser () {
      this.user = new User()
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 1000)
      this.$bvModal.show('user-add')
    },
    async editUser (u) {
      this.user = Object.assign({}, u)
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 1000)
      this.$bvModal.show('user-add')
    },
    async showUserLocation (u) {
      console.log('user-location', u)
      this.user = Object.assign({}, u)
      setTimeout(() => {
        this.$refs.map2.mapObject.invalidateSize()
      }, 1000)
      this.$bvModal.show('user-location')
    },
    async onboardUser (u) {
      this.ou = Object.assign({}, u)
      this.currentStep = 1
      await this.sendToServer()
      alert('user has been sent a message')
    },
    async sendToServer () {
      await axios.post(`/api/admin/onboard?id=${this.ou.id}&step=${this.currentStep}`)
    },
    async completeOnboarding () {
      this.currentStep = 1
      this.$bvModal.hide('onboard-user')
    },
    async gotoStep (s) {
      this.currentStep = s
      await this.sendToServer()
    },
    async setUserDebug (u) {
      setTimeout(async () => {
        await axios.post('/api/admin/user', u)
      }, 100)
    },
    getNameOfUser (id) {
      return this.users.filter(u => u.id === id)[0].name
    }
  }
}
</script>
<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>