import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import EmployeeReport from '@/components/EmployeeReport'
import Settings from "../components/Settings";
import Status from "../components/Status";
import Admin from "../components/Admin";
import Contact from "../components/Contact";
import Dashboard from "../components/Dashboard";
import Tasks from "../components/Tasks";
import CustomerReport from "../components/CustomerReport";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: Login
        },
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard
        },
        {
            path: '/employee-reports',
            name: 'employee-reports',
            component: EmployeeReport
        },
        {
            path: '/employee-reports/:id',
            name: 'one-employee-reports',
            component: EmployeeReport
        },
        {
            path: '/employee-reports/:id/:period',
            name: 'one-employee-period-reports',
            component: EmployeeReport
        },
        {
            path: '/employee-reports/:id/:period/:day',
            name: 'one-employee-period-reports',
            component: EmployeeReport
        },
        {
            path: '/customer-reports',
            name: 'customer-report',
            component: CustomerReport
        },
        {
            path: '/customer-reports/:id',
            name: 'one-customer-report',
            component: CustomerReport
        },
        {
            path: '/customer-reports/:id/:period',
            name: 'one-customer-period-report',
            component: CustomerReport
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: Tasks
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: Contact
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings
        },
        {
            path: '/status',
            name: 'status',
            component: Status
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin
        },
        {
            path: '/admin/debug',
            name: 'admin-debug',
            component: Admin
        }
    ]
})