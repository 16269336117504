<template>
  <section class="page bg-white">
    <b-tabs content-class="mt-3">
      <b-tab title="Tenant">
        <b-modal id="tenant-add" title="Add/Edit Tenant" hide-footer>
          <b-form>
            <b-form-group label="Name">
              <b-form-input v-model="tenant.name"></b-form-input>
            </b-form-group>
            <b-form-group label="EMail">
              <b-form-input v-model="tenant.email" type="email"></b-form-input>
            </b-form-group>
            <b-form-group label="Sim Confirm Call Number">
              <b-form-input v-model="tenant.confirm_call_number" type="text"></b-form-input>
            </b-form-group>
            <b-form-group label="Sync Reminder Time (Hr)">
              <b-form-input v-model="tenant.reminder_time" type="number"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label>Phone Numbers</label>
              <b-form-tags
                  v-model="tenant.phones"
                  separator=" "
                  placeholder="Enter phone#"
                  remove-on-delete
              ></b-form-tags>
              <b-form-text id="tags-remove-on-delete-help" class="mt-2">
                Press <kbd>Backspace</kbd> to remove the last phone# entered
              </b-form-text>
            </b-form-group>
            <b-form-checkbox
                id="role"
                class="mt-3 ml-2"
                v-model="tenant.notify"
                name="role"
                value="true"
                unchecked-value="false">
              Notify Tenant Users about sync activity
            </b-form-checkbox>
            <div class="d-flex justify-content-end">

              <b-button @click="saveTenant">
                <i class="fa fa-save"></i> Save
              </b-button>
            </div>
          </b-form>
        </b-modal>
        <div class="d-flex flex-column ">

          <div class="d-flex justify-content-between mb-3">
            <h4>Tenants</h4>
            <b-button @click="editTenant({})">
              <font-awesome-icon icon="plus"></font-awesome-icon>
              New Tenant
            </b-button>
          </div>

          <div class="d-flex flex-wrap">
            <div class="card mt-2 mr-2" v-for="t of tenants" :key="t.id" style="min-width: 15rem">
              <div class="card-header">
                {{ t.name }}
              </div>
              <div class="card-body">

                <div>
                  <font-awesome-icon icon="envelope"></font-awesome-icon>
                  {{ t.email }}
                </div>
                <div>
                  <ul v-if="t.phones" class="list-unstyled">
                    <li v-for="p of t.phones" :key="p">
                      <font-awesome-icon icon="phone"></font-awesome-icon>
                      {{ p }}
                    </li>
                  </ul>
                </div>
                <div>
                  <font-awesome-icon icon="sim-card"></font-awesome-icon>
                  {{ t.confirm_call_number }}
                </div>

                <div>
                  <font-awesome-icon icon="alarm-clock"></font-awesome-icon> {{t.reminder_time}} Hrs
                </div>
                <div>
                  Notify:
                  <span v-if="t.notify" class="text-success">
                    <font-awesome-icon icon="check"></font-awesome-icon>  Yes
                  </span>
                  <span v-else class="text-danger">
                    <font-awesome-icon icon="times"></font-awesome-icon>  No
                  </span>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <b-button-group>
                  <b-button @click="editTenant(t)">
                    <i class="fa fa-pencil"></i>
                  </b-button>
                  <b-button @click="fixSim(t)" variant="success">
                    <i class="fa fa-sim-card"></i>
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>

        </div>
      </b-tab>
      <b-tab title="Roles">
        <div class="d-flex flex-column">


          <div class="d-flex justify-content-between">
            <h4>Roles</h4>
            <b-button @click="editRole({})">
              <font-awesome-icon icon="plus"></font-awesome-icon>
              New Role
            </b-button>
          </div>

          <div class="d-flex flex-wrap">
            <div class="card mt-2 mr-2" v-for="t of roles" :key="t.id" style="min-width: 20rem">
              <div class="card-header">{{ t.name }}</div>
              <div class="card-body">
                <div>Admin? {{ t.tenant_admin }}</div>
                <div>Manager? {{ t.manager }}</div>
                <div v-if="t.tenant_role">
                  <font-awesome-icon icon="building"></font-awesome-icon>
                  <b-badge v-if="t.tenant">{{ t.tenant.name }}</b-badge>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <b-button @click="editRole(t)">
                  <i class="fa fa-pencil"></i> Edit
                </b-button>
              </div>
            </div>
          </div>


          <b-modal id="role-add"
                   title="Add/Edit Role"
                   hide-footer>
            <div class="d-flex flex-column">
              <b-form-group label="Name" class="ml-2">
                <b-form-input v-model="role.name"></b-form-input>
              </b-form-group>
              <b-form-checkbox
                  id="role"
                  class="mt-3 ml-2"
                  v-model="role.meeting_needs_contact"
                  name="role"
                  value="true"
                  unchecked-value="false">
                While Pinning Meeting, Must select a Contact?
              </b-form-checkbox>
              <b-form-checkbox
                  id="role"
                  class="mt-3 ml-2"
                  v-model="role.tenant_admin"
                  name="role"
                  value="true"
                  unchecked-value="false">
                Is Tenant Admin?
              </b-form-checkbox>
              <b-form-checkbox
                  class="mt-3 ml-2"
                  id="role1"
                  v-model="role.manager"
                  name="role1"
                  value="true"
                  unchecked-value="false">
                Is Manager?
              </b-form-checkbox>
              <b-form-checkbox
                  class="mt-3 ml-2"
                  id="rol3"
                  v-model="role.tenant_role"
                  name="rol3"
                  value="true"
                  unchecked-value="false">
                For a Specific Tenant
              </b-form-checkbox>
              <b-form-group label="Tenant" v-if="role.tenant_role" class="mt-3">
                <b-form-select :options="tenants" text-field="name" v-model="role.tenant_id"
                               value-field="id"></b-form-select>
              </b-form-group>
              <div class="d-flex justify-content-end">
                <b-button @click="saveRole" class="mt-3 ml-2">
                  <i class="fa fa-save"></i> Save
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </b-tab>
      <b-tab title="Users">

        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between mb-2">
            <div class="d-flex justify-content-start mb-2">
              <h4>Users</h4>
              <select v-model="selectedTenant" class="form-control ml-2">
                <option value="">All Tenant</option>
                <option v-for="t of tenants" :key="t.id" :value="t.id">{{ t.name }}</option>
              </select>
            </div>

            <b-button @click="editUser({})">
              <font-awesome-icon icon="plus"></font-awesome-icon>
              New User
            </b-button>
          </div>

          <div class="d-flex flex-wrap">
            <div class="card mr-2 mt-2" v-for="t of filteredUsers" :key="t.id" style="min-width: 20rem">
              <div class="card-header">
                <font-awesome-icon icon="user"></font-awesome-icon>
                {{ t.name }}
              </div>
              <div class="card-body list-group list-group-flush">
                <div class="list-group-item">
                  <font-awesome-icon icon="user-crown"></font-awesome-icon>
                  {{ t.role.name }}
                  <ul class="list-unstyled ml-2 text-muted">
                    <li v-for="(u, idx) of t.sub_users" :key="u">{{ idx + 1 }}. {{ subUser(u) }}</li>
                  </ul>
                </div>
                <div class="list-group-item">
                  <font-awesome-icon icon="envelope"></font-awesome-icon>
                  {{ t.e_mail }}
                </div>
                <div class="list-group-item">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  {{ t.phone }}
                </div>
                <div class="list-group-item">
                  <font-awesome-icon icon="building"></font-awesome-icon>
                  {{ t.tenant.name }}
                </div>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <b-button @click="editUser(t)">
                  <i class="fa fa-pencil"></i> Edit
                </b-button>
              </div>
            </div>
          </div>

        </div>

        <b-modal id="user-add" hide-footer title="Add/Edit User">
          <b-form>
            <b-form-group label="Name">
              <b-form-input v-model="user.name"></b-form-input>
            </b-form-group>
            <b-form-group label="EMail">
              <b-form-input v-model="user.e_mail" type="email"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone">
              <b-form-input v-model="user.phone" type="tel"></b-form-input>
            </b-form-group>
            <b-form-group label="How Old Data to Sync?">
              <b-form-input v-model="user.sync_back" type="number" no-wheel></b-form-input>
              <b-form-text id="input-live-help">set 0 to sync only from the time of install</b-form-text>
            </b-form-group>

            <b-form-group label="Tenant">
              <b-form-select v-model="user.tenant_id"
                             text-field="name"
                             value-field="id"
                             :options="tenants"></b-form-select>
            </b-form-group>

            <b-form-group label="Role">
              <b-form-select v-model="user.role_id"
                             text-field="name"
                             value-field="id"
                             :options="allRoles"></b-form-select>
            </b-form-group>

            <b-form-group label="Password" v-if="isAdminRole">
              <b-form-input v-model="user.password" type="password"></b-form-input>
              <b-form-text id="input-live-help">Optional Password</b-form-text>
            </b-form-group>

            <b-form-group label="Sub Users" v-if="isManagerRole">
              <b-form-select v-model="user.sub_users"
                             text-field="name"
                             value-field="id"
                             multiple
                             :options="nonAdminUsers"></b-form-select>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-button @click="saveUser" class="mt-3">
                <i class="fa fa-save"></i> Save
              </b-button>
            </div>
          </b-form>
        </b-modal>
      </b-tab>
      <b-tab title="Score Config">
        <div class="d-flex">
          <b-form-group label="Select Role">
            <b-form-select @change="loadConfigs()"
                           v-model="selectedRole"
                           :options="roles" value-field="id" text-field="name"></b-form-select>
          </b-form-group>
        </div>
        <div v-if="selectedRole" class="d-flex flex-wrap flex-row">
          <div class="card mb-3 bg-light" style="width: 30rem">
            <div class="card-header">
              <h2>Formula</h2>
            </div>
            <div class="card-body">
              <b-form-group label="Formula">
                <b-form-textarea rows="10" v-model="formula.col_desc"></b-form-textarea>
              </b-form-group>
            </div>
            <div class="card-footer d-flex justify-content-end">

              <b-button @click="saveConfigs(formula)" variant="primary">
                <i class="fas fa-save"></i> Save
              </b-button>
            </div>
          </div>
          <div class="card ml-3 mb-3" v-for="(c, idx) in colConfigs" :key="idx">
            <div class="card-body">
              <b-form-group label="DB Column Name">
                <b-form-input v-model="c.col_name" :disabled="c.id > 0"></b-form-input>
              </b-form-group>
              <b-form-group label="Description">
                <b-form-textarea v-model="c.col_desc"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Operation">
                <b-form-input v-model="c.operation" :disabled="c.id > 0"></b-form-input>
              </b-form-group>
              <b-form-group label="OverAll Cutoff">
                <b-form-input v-model="c.cutoff" type="number"></b-form-input>
              </b-form-group>
              <b-form-group label="Cutoff Per Contact">
                <b-form-input v-model="c.cutoff_contact" type="number"></b-form-input>
              </b-form-group>
            </div>

            <div class="card-footer d-flex justify-content-end">
              <b-button @click="saveConfigs(c)" variant="primary">
                <i class="fas fa-save"></i> Save
              </b-button>
            </div>
          </div>
          <div class="card ml-3 mb-3">
            <div class="card-body d-flex justify-content-start align-content-end align-items-end">

              <b-button @click="addNewConfig" variant="success" class="mr-5">
                Add New Config
              </b-button>

              <b-button @click="dryRun" variant="info">
                Dry Run
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="selectedRole" class="d-flex flex-column">
          <h4 class="mt-3 mb-3">Dry Run Results</h4>
          <div class="row">
            <table class="table">
              <thead>
              <tr>
                <th>Tenant</th>
                <th>Role</th>
                <th>User</th>
                <th>Col A</th>
                <th>Col B</th>
                <th>Col C</th>
                <th>Col D</th>
                <th>Col E</th>
                <th>Col F</th>
                <th>Col G</th>
                <th>Col H</th>
                <th>Col I</th>
                <th>Col Z</th>
                <th>Score</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(r, idx) of dryRuns" :key="idx">
                <td>{{ r.user.tenant.name }}</td>
                <td>{{ r.user.role.name }}</td>
                <td>{{ r.user.name }}</td>
                <td>{{ r.col_a }}</td>
                <td>{{ r.col_b }}</td>
                <td>{{ r.col_c }}</td>
                <td>{{ r.col_d }}</td>
                <td>{{ r.col_e }}</td>
                <td>{{ r.col_f }}</td>
                <td>{{ r.col_g }}</td>
                <td>{{ r.col_h }}</td>
                <td>{{ r.col_i }}</td>
                <td>{{ r.col_j }}</td>
                <td>{{ r.final_score }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span v-else>Select a Role to get Config for that Role</span>

      </b-tab>
      <b-tab title="Role Config">
        <div class="d-flex">
          <form>
            <legend>New Config</legend>
            <b-form-group label="Tenant">
              <b-form-select v-model="cfg.tenant_id" :options="tenants" value-field="id"
                             text-field="name"></b-form-select>
            </b-form-group>
            <b-form-group label="Role">
              <b-form-select v-model="cfg.role_id" :options="roles" value-field="id" text-field="name"></b-form-select>
            </b-form-group>
            <b-form-group label="Meeting Time (in minutes)">
              <b-form-input v-model="cfg.meeting_time" type="number"></b-form-input>
            </b-form-group>
            <b-form-group label="Work Start Time">
              <b-form-timepicker :hour12="false" v-model="cfg.day_start"></b-form-timepicker>
            </b-form-group>
            <b-form-group label="Work End Time">
              <b-form-timepicker :hour12="false" v-model="cfg.day_end"></b-form-timepicker>
            </b-form-group>
            <b-button @click="saveTC()">
              Save
            </b-button>
          </form>
          <div class="ml-5 flex-fill">
            <form>
              <legend>Existing Config</legend>
            </form>

            <table class="table table-striped table-borderless">

              <thead>
              <tr>
                <th>Tenant</th>
                <th>Role</th>
                <th>Meeting Time</th>
                <th>Work Time</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="tc of trcs" :key="tc.id">
                <td>{{ tc.tenant.name }}</td>
                <td>{{ tc.role.name }}</td>
                <td>{{ tc.meeting_time }}</td>
                <td>{{ tc.day_start }} &ndash; {{ tc.day_end }}</td>
                <td>
                  <b-button @click="editTC(tc)">
                    <i class="fa fa-pencil"></i>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Role Fields">
        <div class="d-flex">
          <div class="d-flex justify-content-start mb-2">
            <h4>Role Fields</h4>
            <select v-model="selectedTenant" class="form-control ml-2">
              <option value="">All Tenant</option>
              <option v-for="t of tenants" :key="t.id" :value="t.id">{{ t.name }}</option>
            </select>
          </div>
          <div class="d-flex flex-fill justify-content-end">
            <div>
              <b-button-group size="sm">
                <b-button @click="loadTF()" variant="success">
                  <font-awesome-icon icon="sync"></font-awesome-icon>
                </b-button>
                <b-button @click="addTF()" variant="primary">
                  <font-awesome-icon icon="plus"></font-awesome-icon>
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap">
          <div class="card mr-2 mt-2" style="min-width: 25rem" v-for="(tf, idx) of fieldsFiltered" :key="tf.id">
            <div class="card-body">
              <b-form-group class="mb-3" label="Tenant">
                <b-form-select
                    :options="tenants"
                    text-field="name"
                    value-field="id"
                    v-model="tf.tenant_id" :disabled="savedTF(tf)"></b-form-select>
              </b-form-group>
              <b-form-group class="mb-3" label="Role">
                <b-form-select v-model="tf.role_id"
                               :options="tenantRoles(tf.tenant_id)"
                               value-field="id"
                               text-field="name"
                               :disabled="savedTF(tf)"></b-form-select>
              </b-form-group>
              <b-form-group class="mb-3" label="Field Name">
                <b-form-input v-model="tf.field_name"></b-form-input>
              </b-form-group>
              <b-form-group class="mb-3" label="Field Type">
                <b-form-select :options="fieldTypes"
                               :disabled="savedTF(tf)"
                               v-model="tf.field_type"></b-form-select>
              </b-form-group>
              <b-form-checkbox
                  switch
                  :id="'role'+idx"
                  class="mb-3"
                  v-model="tf.required_field"
                  name="role"
                  value="true"
                  unchecked-value="false">
                Mandatory?
              </b-form-checkbox>
              <b-form-group class="mb-3" label="Options"
                            v-if="tf.field_type === 'single_choice' || tf.field_type === 'multiple_choice'">

                <b-form-tags
                    v-model="tf.field_choices"
                    separator=" "
                    placeholder="Enter Options"
                    remove-on-delete
                ></b-form-tags>
                <b-form-text id="tags-remove-on-delete-help" class="mt-2">
                  Press <kbd>Backspace</kbd> to remove the last choice entered
                </b-form-text>
              </b-form-group>
            </div>
            <div class="card-footer d-flex justify-content-end">
              <b-button-group size="sm">
                <b-button @click="deleteTF(tf, idx)" variant="danger">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                  Delete
                </b-button>
                <b-button variant="success" @click="saveTF(tf)">
                  <font-awesome-icon icon="save"></font-awesome-icon>
                  Save
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import axios from 'axios'
import {Role, Tenant, User} from '../models'

export default {
  name: 'Admin',
  components: {},
  data() {
    return {
      cfg: {
        tenant_id: null,
        role_id: null,
        id: 0,
        meeting_time: 30,
        day_start: '09:00:00',
        day_end: '18:00:00'
      },
      fieldTypes: ['number', 'string', 'single_choice', 'multiple_choice', 'date', 'large_text'],
      fields: [],
      welcomeMessage: '### Welcome to Vue!',
      welcomeMessageHtml: '',
      tenants: [],
      users: [],
      roles: [],
      trcs: [],
      configs: [],
      dryRuns: [],
      tenant: new Tenant(),
      user: new User(),
      role: new Role(),
      selectedRole: null,
      selectedTenant: ''
    }
  },
  async created() {
    await this.loadRoles()
    await this.loadTenants()
    await this.loadUsers()
    await this.loadWelcomeContent()
    await this.loadTC()
    await this.loadTF()
  },
  computed: {
    allRoles() {
      if (!this.user.tenant_id) return this.roles
      else return this.roles.filter(r => !r.tenant_role || r.tenant_id === this.user.tenant_id)
    },
    filteredUsers() {
      if (this.selectedTenant === '') return this.users
      else return this.users.filter(u => u.tenant_id === +this.selectedTenant)
    },
    fieldsFiltered() {
      if (this.selectedTenant === '') return this.fields
      else return this.fields.filter(u => !u.tenant_id || u.tenant_id === +this.selectedTenant)
    },
    nonAdminUsers() {
      return this.users.filter(u => !u.role.super_admin && !u.role.tenant_admin && !u.role.manager).filter(u => u.tenant_id === this.user.tenant_id)
    },
    isAdminRole() {
      if (this.user.role_id) {
        let rs = this.roles.filter(r => r.id === this.user.role_id)
        if (rs.length > 0) {
          return rs[0].tenant_admin || rs[0].manager
        }
      }
      return false
    },
    isManagerRole() {
      if (this.user.role_id) {
        let rs = this.roles.filter(r => r.id === this.user.role_id)
        if (rs.length > 0) {
          return rs[0].manager
        }
      }
      return false
    },
    tags() {
      return Object.keys(this.$store.state.dispMap).filter(k => k.length > 0)
    },
    colConfigs() {
      return this.configs.filter(c => c.is_col)
    },
    formula() {
      let f = this.configs.filter(c => !c.is_col)
      if (f.length === 0) return {'col_desc': 'NA'}
      else return f[0]
    }
  },
  methods: {
    savedTF(tf) {
      return tf.id && tf.id > 0 && this.$route.path !== '/admin/debug'
    },
    addTF() {
      this.fields.unshift({id: null})
    },
    async deleteTF(tf, idx) {
      if (!this.savedTF(tf)) this.fields.splice(idx, 1)
      else {
        await axios.delete(`/api/super-admin/role-form?id=${tf.id}`)
        await this.loadTF()
      }
    },
    async loadTF() {
      let r = await axios.get(`/api/super-admin/role-form`)
      this.fields = r.data || []
    },
    async saveTF(tf) {
      let tfS = Object.assign({}, tf)
      let r = await axios.post(`/api/super-admin/role-form`, tfS)
      tf.id = r.data.id
    },
    tenantRoles(tenant_id) {
      if (!tenant_id) return this.roles
      else return this.roles.filter(r => !r.tenant_role || r.tenant_id === tenant_id)
    },
    subUser(id) {
      let us = this.users.filter(u => u.id === id)
      return us.length === 0 ? "" : us[0].name
    },
    htmlOutput(h) {
      this.welcomeMessageHtml = h
    },
    async loadRoles() {
      let resp = await axios.get('/api/super-admin/role')
      this.roles = resp.data.filter(r => !r.super_admin)
    },
    async loadUsers() {
      let resp = await axios.get('/api/super-admin/user')
      this.users = resp.data
    },
    async loadTenants() {

      let resp = await axios.get('/api/super-admin/tenant')
      this.tenants = resp.data
    },
    async loadConfigs() {
      let resp = await axios.get(`/api/super-admin/score-config?role_id=${this.selectedRole}`)
      this.configs = resp.data
    },
    async loadTC() {
      let resp = await axios.get(`/api/super-admin/tenant-config`)
      this.trcs = resp.data
    },
    async saveTC() {
      await axios.post(`/api/super-admin/tenant-config`, this.cfg)
      this.cfg = {}
      await this.loadTC()
    },
    addNewConfig() {
      this.configs.push({
        col_name: '',
        id: 0,
        col_desc: '',
        operation: '',
        cutoff: 0,
        is_col: true,
        cutoff_contact: 0
      })
    },
    async saveConfigs(c) {
      await axios.post('/api/super-admin/score-config', c)
      await this.loadConfigs()
    },
    async dryRun() {
      let r = await axios.get('/api/super-admin/dry-run')
      this.dryRuns = r.data
    },
    async saveTenant() {
      let t = Object.assign({}, this.tenant)
      t.notify = t.notify === "true"
      await axios.post('/api/super-admin/tenant', t)
      await this.loadTenants()
      this.tenant = new Tenant()
      this.$bvModal.hide('tenant-add')
    },
    async saveRole() {
      await axios.post('/api/super-admin/role', this.role)
      await this.loadRoles()
      this.role = new Role()
      this.$bvModal.hide('role-add')
    },
    async saveUser() {
      await axios.post('/api/super-admin/user', this.user)
      await this.loadUsers()
      this.user = new User()
      this.$bvModal.hide('user-add')
    },
    async loadWelcomeContent() {
      let r = await axios.get('/api/super-admin/app-config')
      this.welcomeMessage = r.data.welcome_message
    },
    async saveWelcomeContext() {
      await axios.post('/api/super-admin/welcome-message', {
        mesg: this.welcomeMessage,
        mesg_html: this.welcomeMessageHtml
      })
    },
    async fixSim(t) {
      await axios.put(`/api/super-admin/assign-sim?tenant_id=${t.id}`)
      alert("done")
    },
    async editUser(u) {
      this.user = Object.assign({}, {
        id: u.id,
        name: u.name,
        e_mail: u.e_mail,
        phone: u.phone,
        sync_back: u.sync_back,
        role_id: u.role_id,
        tenant_id: u.tenant_id
      })
      this.$bvModal.show('user-add')
    },
    async editRole(t) {
      this.role = Object.assign({}, t)
      this.$bvModal.show('role-add')
    },
    async editTC(t) {
      this.cfg = Object.assign({}, t)
    },
    async editTenant(tenant) {
      console.log("Tenant to edit is:", tenant)
      this.tenant = Object.assign({}, {
        id: tenant.id,
        name: tenant.name,
        email: tenant.email,
        phones: tenant.phones,
        sync_tags: tenant.sync_tags
      })
      this.$bvModal.show('tenant-add')
    }
  }
}
</script>
<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>