<template>
  <section class="page">
    <div class="bg-white d-flex w-100 bg-white mb-5 mt-3 p-3 justify-content-between flex-column flex-md-row">
      <h1 class="d-flex align-items-center text-muted flex-fill">
        <i class="fas fa-2x fa-tachometer-alt tm-site-icon"></i>
        <span class="ml-2" v-if="$store.state.tenantAdmin || $store.state.manager">Employee</span>
        <span class="ml-2" v-else>My Communications</span>
      </h1>
      <div class="d-flex align-items-center flex-column flex-md-row justify-content-between ml-0 ml-md-3">
        <div class="d-flex align-items-center  ml-0 ml-md-3 flex-column flex-md-row">

          <b-form-select v-model="days" :options="dayOptions" @change="search">
          </b-form-select>
          <div class="d-flex flex-grow-1 ml-md-2 mt-2 mt-md-0"
               v-if="days === -1"
               style="width: 300px">
            <b-form-datepicker id="example-datepicker"
                               :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               locale="en-IN"
                               @input="search"
                               v-model="daySelect"></b-form-datepicker>

          </div>

        </div>
        <b-form-select v-model="sp" :options="users" @change="search" class="ml-0 ml-md-3 mt-3 mt-md-0" v-if="$store.state.tenantAdmin || $store.state.manager">
        </b-form-select>
      </div>
    </div>
    <div class="row" v-if="!sp">
      <div class="col-12 p-5 bg-white">
        <div class="alert alert-danger">
          Select An Employee
        </div>
      </div>
    </div>
    <div class="d-flex flex-column" v-if="sp">
        <div class="card p-3 mb-3">
          <div class=" d-flex justify-content-center text-center" style="font-size: larger">
            <strong>Day's Score</strong> : {{score}}%
          </div>
        </div>
        <div class="card p-3 mb-3">
          <div class=" d-flex flex-column justify-content-center text-center" style="font-size: larger">

            <h5 class="mb-3 text-center">Sales Performance</h5>
            <div class="d-none d-md-block">
              <column-chart :data="chartData" :min="0" :max="100"></column-chart>
            </div>
            <div class="d-block d-md-none">
              <bar-chart :data="chartData" :min="0" :max="100"></bar-chart>
            </div>
          </div>
        </div>
        <div class="card p-3  bg-white">
          <div class="card-header d-flex justify-content-between align-items-center align-content-center flex-column flex-md-row">
            <h4 class="mb-3 mb-md-0">Activities</h4>
            <b-form-checkbox-group id="checkbox-group-2" v-model="messageTypes" name="flavour-2" switches
                                   @change="delaySearch">
              <b-form-checkbox value="MEETING"><i class="far fa-location"></i></b-form-checkbox>
              <b-form-checkbox value="WHATSAPP"><i class="fab fa-whatsapp"></i></b-form-checkbox>
              <b-form-checkbox value="CALL"><i class="far fa-phone"></i></b-form-checkbox>
              <b-form-checkbox value="SMS"><i class="far fa-envelope"></i></b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="card-body">
            <b-pagination
                v-if="communications.length > perPage"
                :hide-goto-end-buttons="true"
                align="fill"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
            ></b-pagination>
            <div class="list-group list-group-flush">

              <div class="list-group-item" v-for="c of communications" :key="c.id">
                <a class="d-flex flex-column" @click.prevent="expandComm(c)" style="cursor: pointer;">

                  <display-content :c="c" sales-person="false"></display-content>
                </a>
              </div>
            </div>

            <b-card v-if="communications.length === 0" bg-variant="warning">
              <b-card-text>No Activities Found</b-card-text>
            </b-card>

            <b-pagination
                v-if="rows > perPage"
                :hide-goto-end-buttons="true"
                align="fill"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
            ></b-pagination>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import DisplayContent from './DisplayContent'

export default {
  name: 'HomePage',
  components: {DisplayContent},
  data () {
    return {
      customerSearch: '',
      days: 1,
      daySelect: moment().format("YYYY-MM-DD"),
      whom: 'SP',
      sp: null,
      cust: null,
      score: 'NA',
      chartData: [],
      dayOptions: [
        {text: 'Today', value: 0},
        {text: 'Yesterday', value: 1},
        {text: 'Custom Date', value: -1},
      ],
      whomList: [
        {value: 'SP', text: 'Employee'},
        {value: 'CUST', text: 'Customer'}
      ],
      messageTypes: ['CALL', 'SMS', 'WHATSAPP', 'MEETING'],
      communications: [],
      userTasks: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      contactedCustomers: [],
      task: {
        task_due: new Date(),
        contact_id: null,
        user_id: null
      }
    }
  },
  async created () {

    if (this.$route.params.id) {
      this.sp = this.$route.params.id
    } else if (!this.$store.state.tenantAdmin) {
      this.sp = this.$store.state.userId
    }
    let period = this.$route.params.period
    if (period === '0' || +period > -1) {
      this.days = +period
    }
    if(+period === -1){
      this.days = -1
      this.daySelect = this.$route.params.day
    }

    await this.search()
  },
  watch: {
    async currentPage() {
      await this.search()
    }
  },
  computed: {
    users: function () {
      let validUser = this.$store.state.users.filter(u => u.value)
      if (this.$store.state.tenantAdmin)
        return validUser.filter(u => u.role.tenant_admin === false)
      else
        return validUser.filter(u => u.id === this.$store.state.userId)
    },
    customers: function () {
      return this.$store.state.customers.filter(u => u.value)
    }
  },
  methods: {
    async addTask ({contactId, userId}) {
      console.log(`adding task for ${contactId}, ${userId}`)
      this.task.contact_id = contactId || null
      this.task.assigned_to = userId || null
      await this.$store.dispatch('showTask')
    },
    async delaySearch () {
      setTimeout(async () => {
        await this.search()
      }, 300)
    },
    async search () {
      let ct = _.join(this.messageTypes.map(b => `comm_types=${b}`), '&')
      let start = (this.currentPage-1)*this.perPage
      let q = `user_id=${this.sp}&days=${this.days}&date=${this.daySelect}&${ct}&start=${start}&per_page=${this.perPage}`
      if (this.sp) {

        let resp = await axios.get(`/api/communication?${q}`)
        this.communications = (resp.data.communications || []).map(c => {
          c['sent_at'] = moment(c.sent_at.replace('Z','')).format('DD/MMM/YYYY hh:mm a')
          return c
        })
        this.rows = resp.data.count

        try {
          let sc = await axios.get(`/api/score?${q}`)
          this.score = sc.data.score
        }catch (e){
          console.error('error in fetching score', e)
          this.score = 'NA'
        }

        let scores = await axios.get(`/api/scores?user_id=${this.sp}`)
        this.chartData = _.reverse((scores.data.scores || []).map(sc => {
          return [
            moment(sc.at).format('DD/MMM'), sc.final_score
          ]
        }))
        console.log(this.chartData)
      }

    },
    async loadTasks () {
      let resp = await axios.get(`/api/user-task?user_id=${this.sp}`)
      this.userTasks = (resp.data || []).map(d => {
        d['created_at'] = moment(d['created_at']).format('YYYY-MM-DD')
        d['task_due'] = moment(d['task_due']).format('YYYY-MM-DD')
        return d
      })
    },
    async expandComm (comm) {
      console.log('expanding comm', comm)
    },
    customerCommunications (cId) {
      return this.communications.filter(c => c.contact_id === cId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 576px) {
  .m-w-100 {
    width: 100vw;
  }
}
@media (min-width: 576px) {

}
</style>
