<template>
    <section class="page bg-white">
        <div class="row mb-3">
            <h3 class="col-12 col-md-3">Contacts</h3>
            <div class="col-12 col-md-3">
                <input type="text" class="form-control"
                       placeholder="Search Contacts" v-model="filter">
            </div>
        </div>
        <div class="card-columns">
            <div class="card" v-for="c of contacts" :key="c.id">
                <div class="card-body">
                    <h5 class="card-title">{{c.name}}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{c.phone}}</h6>
                </div>
                <div class="card-footer text-right">

                    <div class="btn-group btn-group-sm">
                        <button class="btn btn-info" @click="viewContact(c)">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button @click="editContact(c)" class="btn btn-primary">
                            <i class="fa fa-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="edit-contact" @ok="saveContact" title="Edit/Save Contact">
            <b-form>
                <b-form-group label="Name">
                    <b-form-input v-model="contact.name"></b-form-input>
                </b-form-group>
                <b-form-group label="Phone">
                    <b-form-input v-model="contact.phone" type="tel" readonly></b-form-input>
                </b-form-group>
            </b-form>
        </b-modal>
    </section>
</template>
<script>
    import _ from 'lodash'
    import axios from 'axios'

    export default {
        name: 'Contact',
        data() {
            return {
                allContacts: [],
                filter: '',
                contact: {}
            }
        },
        computed: {
            contacts() {
                if (this.filter.length === 0) return _.take(this.allContacts, 50)
                else return _.take(this.allContacts.filter(c =>
                    c.name.toLowerCase().indexOf(this.filter) > -1 ||
                    c.phone.indexOf(this.filter) > -1
                ), 50)
            }
        },
        created() {
            this.loadContacts()
        },
        methods: {
            loadContacts() {
                axios.get('/api/contact')
                    .then(resp => {
                        this.allContacts = resp.data
                    })
            },
            saveContact() {
                axios.post('/api/contact', {id: this.contact.id, name: this.contact.name})
                .then(() => {
                    this.loadContacts()
                })
            },
            editContact(c){
                this.contact = Object.assign({}, c)
                this.$bvModal.show('edit-contact')
            },
            viewContact(c){
                this.$router.push(`/customer-reports/${c.id}`)
            }
        }
    }
</script>
<style scoped></style>