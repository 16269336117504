<template>
  <section class="page">
    <div class="bg-white d-flex w-100 bg-white mb-5 mt-3 p-3 justify-content-between flex-column flex-md-row">
      <h1 class="d-flex align-items-center text-muted">
        <i class="fas fa-2x fa-tachometer-alt tm-site-icon"></i>
        <span class="ml-2">Customer</span>
      </h1>

    </div>
    <div class="row mb-5">
      <div class="col-12 p-5 bg-white">
        <h4 class="pb-3">
          Select A Customer
        </h4>
        <v-select v-model="sp" :options="customers" @input="search" label="text"
                  :reduce="p => p.id"
                  style="min-width: 300px" class="flex-fill">
        </v-select>
      </div>
    </div>
    <div class="row" v-if="sp">
      <div class="col-12 bg-white p-3">
        <div class="card bg-white">
          <div class="card-header d-flex justify-content-between align-items-center align-content-center">
            <h4>Activities</h4>
            <b-form-checkbox-group id="checkbox-group-2" v-model="messageTypes" name="flavour-2" switches
                                   @change="delaySearch">
              <b-form-checkbox value="MEETING"><i class="far fa-location"></i></b-form-checkbox>
              <b-form-checkbox value="WHATSAPP"><i class="fab fa-whatsapp"></i></b-form-checkbox>
              <b-form-checkbox value="CALL"><i class="far fa-phone"></i></b-form-checkbox>
              <b-form-checkbox value="SMS"><i class="far fa-envelope"></i></b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="card-body">
            <b-pagination
                v-if="communications.length > 0"
                :hide-goto-end-buttons="true"
                align="fill"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
            ></b-pagination>
            <div class="list-group list-group-flush">
              <div class="list-group-item" v-for="c of communications" :key="c.id">
                <a class="d-flex flex-column" @click.prevent="expandComm(c)" style="cursor: pointer;">
                  <display-content :c="c" sales-person="false"></display-content>
                </a>
              </div>
            </div>

            <b-pagination
                :hide-goto-end-buttons="true"
                align="fill"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
            ></b-pagination>

          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DisplayContent from "./DisplayContent";
import _ from "lodash";

export default {
  name: 'HomePage',
  components: {DisplayContent},
  data() {
    return {
      days: 1,
      sp: null,
      communications: [],
      messageTypes: ['CALL', 'SMS', 'WHATSAPP', 'MEETING'],
      customerTasks: [],
      currentPage: 1,
      rows: 0,
      perPage: 10,
      task: {
        task_due: new Date(),
        contact_id: null,
        user_id: null
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.sp = this.$route.params.id
    }
    let period = this.$route.params.period;
    if (period === '0' || +period > -1) {
      this.days = +period
    }
    await this.search()
  },
  computed: {
    users: function () {
      return this.$store.state.users.filter(u => u.value)
    },
    customers: function () {
      return this.$store.state.customers.filter(u => u.value)
    }
  },
  watch: {
    async currentPage() {
      await this.search()
    }
  },
  methods: {
    async addTask({contactId, userId}) {
      console.log(`adding task for ${contactId}, ${userId}`)
      this.task.contact_id = contactId || null
      this.task.assigned_to = userId || null
      await this.$store.dispatch("showTask")
    },
    async delaySearch() {
      setTimeout(async () => {
        await this.search()
      }, 300)
    },
    async search() {
      let ct = _.join(this.messageTypes.map(b => `comm_types=${b}`), "&")
      let q = `contact_id=${this.sp}&days=${this.days}&${ct}`
      if (this.sp) {

        let resp = await axios.get(`/api/communication?${q}`)
        this.communications = (resp.data.communications || []).map(c => {
          c['sent_at'] = moment(c.sent_at).format("DD/MMM/YYYY hh:mm a")
          return c
        })
        this.rows = resp.data.count

      }

    },
    expandComm: function (comm) {
      console.log("expanding comm", comm)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
