<template>
  <div class="login-container d-flex align-items-center align-content-center justify-content-center">
    <form
        v-if="loginPage"
        class="form-signin d-flex w-100 flex-column bg-white h-100">
      <img class="mb-4 align-self-center" src="../assets/sygnal.png" alt="" style="max-width: 100px">
      <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>

      <b-form-group label="Mobile Number/EMail">
        <input type="text" id="inputEmail" class="form-control mb-1" v-model="otpAuth.mobile"
               :disabled="otpAuth.action === 'VALIDATE'"
               placeholder="Mobile Number/EMail" required autofocus>
      </b-form-group>

      <b-button variant="success" @click="checkAuthType" v-if="otpAuth.authType === ''">
        Next &rarr;
      </b-button>

      <b-form-group label="OTP"
                    v-if="otpAuth.action === 'VALIDATE' && otpAuth.authType === 'OTP' && loginType === 'OTP'">
        <input type="text" class="form-control" v-model="otpAuth.otp" placeholder="OTP" required>
      </b-form-group>

      <b-form-group label="Password" v-if="otpAuth.authType === 'PASSWORD'">
        <input type="password"
               class="form-control"
               v-model="otpAuth.password"
               placeholder="Password" required>
      </b-form-group>

      <button class="btn btn-lg btn-primary btn-block mt-2" type="submit"
              @click.prevent="doPasswordLogin"
              v-if="otpAuth.authType === 'PASSWORD'">
        <font-awesome-icon :icon="['fas', 'key']"></font-awesome-icon>
        Login
      </button>
      <button class="btn btn-lg btn-primary btn-block mt-2"
              type="submit"
              @click.prevent="doLogin"
              v-if="otpAuth.authType === 'OTP' && (otpAuth.action === 'REQUEST' || loginType === 'OTP')">
        <font-awesome-icon :icon="loginIcon"></font-awesome-icon>
        {{ loginText }}
      </button>
      <p class="mt-5 mb-3 text-muted">&copy; 2017-2020</p>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import { OtpAuth } from '@/models'

export default {
  name: 'Login',
  data () {
    return {
      loginPage: false,
      qrCode: '',
      loginType: 'OTP',
      otpAuth: new OtpAuth()
    }
  },
  computed: {
    loginIcon () {
      if (this.otpAuth.action === 'REQUEST' && this.loginType === 'OTP') {
        return ['fas', 'key']
      }
      if (this.otpAuth.action === 'VALIDATE' && this.loginType === 'OTP') {
        return ['fas', 'sign-in']
      }
      return 'sign-in'
    },
    loginText () {

      if (this.otpAuth.action === 'REQUEST' && this.loginType === 'OTP') {
        return 'Get OTP'
      }
      if (this.otpAuth.action === 'VALIDATE' && this.loginType === 'OTP') {
        return 'Sign In'
      }
      return 'Sign In'
    }
  },
  async created () {


    console.log(this.$route)
    if (this.$route.fullPath === '/logout') {
      this.$store.commit('logout')
      await this.$router.push('/login')
      this.loginPage = true
    } else {
      this.loginPage = true
    }
  },
  methods: {
    async checkAuthType () {
      let r = await axios.post(`/auth/auth-type?mobile=${this.otpAuth.mobile}`)
      console.log(r)
      this.otpAuth.authType = r.data.auth.toUpperCase()
    },
    async doPasswordLogin () {
      let resp = await axios.post(`/auth/login`, this.otpAuth)
      console.log(resp)
      this.$store.commit('loggedIn', {at: resp.data.authToken, me: resp.data.user})
      await this.$router.push('/')
    },
    async doLogin () {
      try {
        let debug = process.env.NODE_ENV === 'development'
        if (this.otpAuth.action === 'REQUEST') {
          await axios.post(`/auth/otp?debug=${debug}`, this.otpAuth)
          this.otpAuth.action = 'VALIDATE'

        } else {
          if (this.loginType === 'OTP') {
            let resp = await axios.post(`/auth/otp?debug=${debug}`, this.otpAuth)
            this.$store.commit('loggedIn', {at: resp.data.authToken, me: resp.data.user})
            await this.$router.push('/')

          }
        }
      } catch (e) {
        console.error('error in dologin', e)
      }

    }
  }
}
</script>
<style scoped>

.login-container {
  min-height: 100vh;
}

@media (max-width: 576px) {
  .login-container {
    padding: 15% 0%;
  }

  .form-signin {
    padding: 2em;
  }
}

@media (min-width: 576px) {
  .login-container {
    padding: 15% 25%;
  }

  .form-signin {
    padding: 5em;
  }
}


</style>