export function OtpAuth() {
    this.mobile = ""
    this.action = "REQUEST"
    this.otp = ""
    this.password = ""
    this.authType = ""
}

export function Tenant() {
    this.name = ""
    this.email = ""
    this.confirm_call_number = ""
    this.reminder_time = 18
    this.phones = []
    this.sync_tags = []
    this.notify = true

}

export function Task() {
    this.contact = {}
    this.user = {}
    this.assigned_to = {}
    this.comments = []
    this.notes = ''
}

export function User() {
    this.name = ""
    this.e_mail = ""
    this.phone = ""
    this.role_id = null
    this.sub_users = []
    this.user_debug = false
    this.sync_back = 30
    this.tenant_id = null
    this.enabled = true
    this.location = [12.897035470899471, 77.56324501143929]
}

export function Role() {
    this.name = ""
    this.super_admin = false
    this.tenant_admin = false
    this.tenant_role = false
    this.manager = false
    this.tenant_id = null
}

export function logError(err) {
    console.error('error in api call', err)
}